/* eslint-disable camelcase */
// const proxy = require("http-proxy-middleware")

module.exports = {
  siteMetadata: {
    title: `Vaporware`,
    siteUrl: `https://www.vaporware.net`,
  },
  // developMiddleware: app => {
  //   app.use(
  //     "/.netlify/functions/",
  //     proxy({
  //       target: "http://localhost:34567",
  //       pathRewrite: {
  //         "/.netlify/functions/": "",
  //       },
  //     })
  //   )
  // },
  plugins: [
    `gatsby-plugin-react-helmet`,
    `gatsby-plugin-root-import`,
    `gatsby-plugin-catch-links`,
    `gatsby-plugin-remove-fingerprints`,
    `gatsby-plugin-image`,
    `gatsby-transformer-sharp`,
    {
      resolve: `gatsby-plugin-sharp`,
      options: {
        defaultQuality: 75,
      }
    },
    {
      resolve: `gatsby-plugin-scroll-reveal`,
      options: {
        threshold: 0.7,
      }
    },
    {
      resolve: `gatsby-plugin-scroll-indicator`,
      options: {
        paths: [`/insights/**`,`/work/**`],
        color: '#003385',
      }
    },
    {
      resolve: `gatsby-plugin-sitemap`,
      options: {
        excludes: [
          `/thanks`,
        ],
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/assets/images`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `img`,
        path: `${__dirname}/static/img`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `content`,
        path: `${__dirname}/content`,
      },
    },
    {
      resolve: `gatsby-transformer-remark`,
      options: {
        plugins: [
          `gatsby-remark-external-links`,
          `gatsby-remark-prismjs`,
        ]
      },
    },
    `gatsby-plugin-sass`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `Vaporware® Consulting`,
        short_name: `Vaporware®`,
        start_url: `/`,
        background_color: `#663399`,
        theme_color: `#663399`,
        display: `fullscreen`,
        icon: `src/assets/images/favicon.png`,
      },
    },
    {
      resolve: `gatsby-plugin-segment-js`,
      options: {
        prodKey: `snhe4k9abb`,
        devKey: `pZwB70ukU4UVHcPlg5dZYvAjdT2mHz0Z`,
        trackPage: true,
      },
    },
  ],
}
