import React from "react"
import { graphql, Link } from "gatsby"
import moment from "moment"
import PropTypes from "prop-types"

import Layout from "src/components/Layout"
import PageHero from "src/components/PageHero"
import { getExcerpt } from "src/utils/posts"

import iconReply from "src/assets/images/icon-reply.svg"
import iconSubscribe from "src/assets/images/icon-subscribe.svg"
import iconForward from "src/assets/images/icon-forward.svg"
import logoMark from "src/assets/images/vaporware-logo-mark.svg"
import "./letterTemplate.scss"

const letterEncoder = content => {
  return encodeURIComponent(content.trim())
}

const SITE_URL = require('../../gatsby-config').siteMetadata.siteUrl

const LetterTemplate = ({data: {page: {
  html, frontmatter: {
  meta, sender, categories
}}}}) => {
  const PAGE_URL = `${SITE_URL}${meta.path}`
  
  const excerpt = getExcerpt({text: html, characeters: 160})
  const newMeta = { 
    ...meta,
    description: meta.description || excerpt,
    image: meta.image || {childImageSharp: {fluid: { src:"/img/card-default-letter.png"}}},
  }

  const replyButton = (
    <a
      className="btn btn-default"
      target="_blank"
      rel="noopener noreferrer"
      href={
        `mailto:${sender.email}?subject=${letterEncoder(`Re: ${meta.title}`)}
         &body=${letterEncoder(`In response to ${PAGE_URL}...`)}`}
    >
      <img src={iconReply} alt="Reply" />
      Reply
    </a>
  )

  const subscribeButton = (
    <a
      className="btn btn-default"
      target="_blank"
      rel="noopener noreferrer"
      href={
        `mailto:${sender.email}?subject=${letterEncoder(`Please subscribe me to your letters.`)}
         &body=${letterEncoder(`I was reading ${PAGE_URL}, and wanted to hear from you more.`)}`}
    >
      <img src={iconSubscribe} alt="Subscribe" />
      Subscribe
    </a>
  )

  const forwardButton = (
    <a
      className="btn btn-default"
      target="_blank"
      rel="noopener noreferrer"
      href={
        `mailto:?subject=${letterEncoder(`I thought you might like these insights by ${sender.name} at Vaporware®`)}
        &body=${letterEncoder(`I was reading ${PAGE_URL}, and thought you might want to read it.`)}`}
    >
      <img src={iconForward} style={{transform: "scaleX(-1)"}} alt="Forward" />
      Forward
    </a>
  )

  return (
    <Layout meta={newMeta} bodyClass="gray">
      <div className="letter-template">
        <PageHero
          className="letter"
          overlay={false}
        />
        <div className="letter-wrapper">
          <section className="letter-header">
            <h1>{meta.title}</h1>
            <div className="categories">Letter, {categories.join(", ")}</div>
            <div className="row">
              <img className="sender-image" src={sender.image} alt={sender.name} />
              <div className="transmission">
                <div className="sender">{sender.name} at Vaporware®</div>
                <div className="receiver">to me</div>
              </div>
              <div className="timestamp">
                {moment(meta.date).format("LL, h:mm A")}
              </div>
              <div className="actions">
                {replyButton}
              </div>

            </div>
          </section>
          <section className="letter-body">
            <div dangerouslySetInnerHTML={{ __html: html }} />
            <div className="signature">
              <img src={sender.signature} alt="Dan Moore"/>
              <p>{sender.title}</p>
            </div>
          </section>
          <section className="letter-footer">

            <div className="post-mark">
              <Link to="/insights" alt="Read more insights">
                <img src={logoMark} alt="Vaporware" />
              </Link>
            </div>

            <p>Like these thoughts? Forward this email to a friend. </p>

            <p>Want my perspective on something else? Reply with a question or topic to discuss and I’d be happy to share my opinion.</p>
            
            <p>Want to read our latest insights? Subscribe to get them delivered to your inbox every week.</p>
            <div className="actions">
              {subscribeButton}
              {replyButton}
              {forwardButton}
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

LetterTemplate.defaultProps = {
  data: {
    page: {
      frontmatter: {
        meta: {},
        sender: {
          name: "",
          email: "",
          image: "",
          title: "",
          signature: "",
        },
        categories: [],
        tags: [],
      },
      html: "",
    },
  },
}

LetterTemplate.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      frontmatter: PropTypes.shape({
        meta: PropTypes.object,
        sender: PropTypes.shape({
          email: PropTypes.string,
          name: PropTypes.string,
          image: PropTypes.string,
          title: PropTypes.string,
          signature: PropTypes.string,
        }),
        categories: PropTypes.arrayOf(PropTypes.string),
        tags: PropTypes.arrayOf(PropTypes.string),
      }),
      html: PropTypes.string,
    }),
  }),
}

export default LetterTemplate

export const pageQuery = graphql`
  query($path: String!) {
    page: markdownRemark(frontmatter: { meta: { path: { eq: $path } } }) {
      html
      ...MetaFragment
      frontmatter {
        title
        sender {
          email
          name
          image
          signature
          title
        }
        categories
        tags
      }
    }
  }
`
